<template>
<div class="form-group">
    <slot></slot>
</div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.form-group {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 0.25em;
    border-radius: $border-radius;
    width: 75vw;
    max-width: min(100vw, 30em);
    padding: 0.25em;
    padding-left: 1em;
    background-color: $color-dark;
    color: $color-light;

    > :deep(:not(.form-group-title)) {
        grid-column-start: 2;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        border-radius: 0;
        max-width: 100%;

        grid-template-columns: min-content 1fr;
        padding-left: 0.5em;
    }
}
</style>