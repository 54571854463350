import { reactive } from 'vue'
import { StoreBase, StoreType } from './store-base'

export type HelpTypes = 'actor' | 'practical' | 'other'

export const actorRoles = [
    'Kamel',
    'Ängel som hjälper Josef',
    'Ängel som ropar',
    'Soldat',
    'Herde',
    'Lamm',
    'Marknaden - Säljare',
    'Marknaden - Sjunga',
    'Skriftlärd',
    'Värdshusvärd',
    'Ljusängel'] as const
export type ActorRoles = typeof actorRoles[number]

export function GetActionRoleText(actorRole: ActorRoles) {
    switch (actorRole) {
        case 'Kamel':
            return 'Kamel';
        case 'Ängel som hjälper Josef':
            return 'Josefs ängel';
        case 'Ängel som ropar':
            return 'Ängel som ropar';
        case 'Soldat':
            return 'Soldat';
        case 'Herde':
            return 'Herde';
        case 'Lamm':
            return 'Lamm';
        case 'Marknaden - Säljare':
            return 'Marknaden - Säljare';
        case 'Marknaden - Sjunga':
            return 'Marknaden - Sång';
        case 'Skriftlärd':
            return 'Skriftlärd';
        case 'Värdshusvärd':
            return 'Värdshusvärd';
        case 'Ljusängel':
            return 'Ängel som håller ljus';
        default:
            throw new Error('Unknown action role: ' + actorRole);
    }
}

export function GetActionRoleDescription(actorRole: ActorRoles) {
    switch (actorRole) {
        case 'Kamel':
            return 'En av två personer i kameldräkt';
        case 'Ängel som hjälper Josef':
            return 'Pratar med Josef när han tvivlar';
        case 'Ängel som ropar':
            return 'Ropar i första scenen';
        case 'Soldat':
            return 'En av de togika soldaterna\nsom sjunger om skattskrivningen';
        case 'Herde':
            return 'Leker med fåren';
        case 'Lamm':
            return 'Passar yngre barn (3-6 år)';
        case 'Marknaden - Säljare':
            return 'Går runt i bakgrunden på marknaden';
        case 'Marknaden - Sjunga':
                return 'Sjunger / pratar på marknaden';
        case 'Skriftlärd':
            return 'Berättar för de vise männen\natt de ska gå till Jerusalem';
        case 'Värdshusvärd':
            return 'Lånar ut sitt stall\ntill Josef & Maria';
        case 'Ljusängel':
            return 'Håller ett ljus i handen när Jesus ska födas (tidigare glitterblåsare)';
        default:
            throw new Error('Unknown action role: ' + actorRole);
    }
}

export const practicalHelps = [
    'Ställa upp rekvisita',
    'Städa',
    'Mat',
] as const
export type PracticalHelps = typeof practicalHelps[number]

export function GetPracticalHelpText(practicalHelp: PracticalHelps) {
    switch (practicalHelp) {
        case 'Ställa upp rekvisita':
            return 'Ställa upp rekvisita';
        case 'Städa':
            return 'Städa';
        case 'Mat':
            return 'Ordna mat';
        default:
            throw new Error('Unknown practical help: ' + practicalHelp);
    }
}

export function GetPracticalHelpDescription(practicalHelp: PracticalHelps) {
    switch (practicalHelp) {
        case 'Ställa upp rekvisita':
            return 'Hjälpa till med att rigga upp';
        case 'Städa':
            return 'Hjälpa till med att städa efteråt';
        case 'Mat':
            return 'Hjälpa till att göra mat eller fika';
        default:
            throw new Error('Unknown practical help: ' + practicalHelp);
    }
}

/**
 * The store interface
 */
export interface StoreData extends Object {
    name?: string;
    age?: number;
    helpType?: HelpTypes;
    actorRoles: ActorRoles[];
    practicalHelps: PracticalHelps[];
    phone?: string;
    email?: string;
    otherHelpText?: string;
    approvedSaveInformationForFuture?: boolean;
}

class Store extends StoreBase<StoreData> {
    constructor() {
        super(StoreType.sessionStorage, 'form');
    }

    protected defaultData(): StoreData {
        return {
            actorRoles: [],
            practicalHelps: [],
        };
    }

    public setName(name: string) {
        this.reactiveState.name = name;
    }

    public setAge(age: number) {
        this.reactiveState.age = age;
    }

    public setHelpType(helpType: HelpTypes) {
        this.reactiveState.helpType = helpType;
    }

    public addActionRole(actorRole: ActorRoles) {
        this.reactiveState.actorRoles.push(actorRole);
    }

    public removeActionRole(actorRole: ActorRoles) {
        this.reactiveState.actorRoles = this.reactiveState.actorRoles.filter((role) => role !== actorRole);
    }

    public addPracticalHelp(practicalHelp: PracticalHelps) {
        this.reactiveState.practicalHelps.push(practicalHelp);
    }

    public removePracticalHelp(practicalHelp: PracticalHelps) {
        this.reactiveState.practicalHelps = this.reactiveState.practicalHelps.filter((help) => help !== practicalHelp);
    }

    public setPhone(phone: string) {
        this.reactiveState.phone = phone;
    }

    public setEmail(email: string) {
        this.reactiveState.email = email;
    }

    public setOtherHelpText(otherHelpText: string) {
        this.reactiveState.otherHelpText = otherHelpText;
    }

    public setApprovedSaveInformationForFuture(approvedSaveInformationForFuture: boolean) {
        this.reactiveState.approvedSaveInformationForFuture = approvedSaveInformationForFuture;
    }

    public clearStore() {
        this.reactiveState = reactive(this.defaultData());
    }
}

const store: Store = new Store();
export default store;