export interface HslColor {
    hue: number;
    saturation: number;
    lightness: number;
};

export class ColorUtil {
    private hslColor: HslColor;

    constructor(hue: number, saturation: number, lightness: number) {
        this.hslColor = {
            hue: hue,
            saturation: saturation,
            lightness: lightness
        };
    }

    public toString(): string {
        return `hsl(${this.hslColor.hue}, ${this.hslColor.saturation*100}%, ${this.hslColor.lightness*100}%)`;
    }

    public withLightness(lightness: number): ColorUtil {
        return new ColorUtil(this.hslColor.hue, this.hslColor.saturation, lightness);
    }
}