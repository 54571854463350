import { StoreData } from "@/store/form-store";

export type method = 'get' | 'post' | 'put' | 'delete' | 'patch' | 'head' | 'options';

class Api {
    private url = `${process.env.VUE_APP_BACKEND_URL}/api`;
    private apiKey = '_MS0CDZcXB829vlHkAyNO0Yj-sSN_QOhQwp6CTYBHNCDAzFuFcQ8BQ==';

    public addRegistration(formData: StoreData): Promise<void> {
        return this.request<void>('post', 'add-registration', formData);
    }

    private get parameters() {
        return `?code=${this.apiKey}&clientId=web`;
    }

    /**
     * Sends a request to the backend and returns the response.
     * @param method The type of request to make.
     * @param action The action to perform on the backend.
     * @param data Any data to send to the backend.
     */
    private async request<T>(method: method, action: string, data?: any): Promise<T> {
        const url = `${this.url}/${action}${this.parameters}`;

        const body = data == null ? undefined
            : JSON.stringify(data);

        const contentType = 'application/json';

        // Fetch data from the API
        const request = new Request(url, {
            method: method,
            body,
            headers: {
                'Content-Type': contentType
            }
        });

        const response = await fetch(request);
        
        if (response.status === 204) { return undefined as any; } // No content
        if (!response.ok) { return undefined as any; } // Some error
        
        // Parse the response body as an object
        try {
            const text = await response.text();
            if (text === null || text === '') { return undefined as any; } // No proper content

            const body = JSON.parse(text);
            return body;
        } catch (err) {
            // Do nothing, just return nothing
            return undefined as any;
        }
    }
}

const api = new Api();
export default api;