import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Gdpr from '@/views/gdpr.vue'
import Page1Welcome from '@/views/page.1.welcome.vue'
import Page2Name from '@/views/page.2.name.vue'
import Page3HelpType from '@/views/page.3.help-type.vue'
import Page4Roles from '@/views/page.4.roles.vue'
import Page4Practical from '@/views/page.4.practical.vue'
import Page4Other from '@/views/page.4.text.vue'
import Page5Contact from '@/views/page.5.contact.vue'
import Page6Summary from '@/views/page.6.summary.vue'
import Page7ThankYou from '@/views/page.7.thank-you.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Page1Welcome
    },
    {
        path: '/gdpr',
        component: Gdpr
    },
    {
        path: '/name',
        component: Page2Name
    },
    {
        path: '/help-type',
        component: Page3HelpType
    },
    {
        path: '/actor',
        component: Page4Roles
    },
    {
        path: '/practical',
        component: Page4Practical
    },
    {
        path: '/other',
        component: Page4Other
    },
    {
        path: '/contact',
        component: Page5Contact
    },
    {
        path: '/summary',
        component: Page6Summary
    },
    {
        path: '/thank-you',
        component: Page7ThankYou
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
