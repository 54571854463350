import { createApp } from 'vue';
import App from './app.vue';
import './registerServiceWorker';
import router from './router';
import Icon from '@/components/icon.vue';

import FormGroup from '@/components/form-group.vue';
import FormGroupTitle from '@/components/form-group-title.vue';
import FormButton from '@/components/form-button.vue';
import FancyButton from '@/components/fancy-button.vue';

createApp(App)
    .use(router)

    // Global components
    .component('icon', Icon)
    .component('form-group', FormGroup)
    .component('form-group-title', FormGroupTitle)
    .component('form-button', FormButton)
    .component('fancy-button', FancyButton)

    .mount('#app');
